<template>
  <b-row>
    <b-col sm="6">
      <RecentUpdatedMeters />
    </b-col>
    <b-col sm="6">
      <RecentUpdatedInvoices />
    </b-col>
    <b-col sm="6">
      <RecentUpdatedAccounts />
    </b-col>
    <b-col sm="6">
      <RecentUpdatedPayments />
    </b-col>
  </b-row>
</template>

<script>
import RecentUpdatedMeters from "./RecentUpdatedMeters";
import RecentUpdatedAccounts from "./RecentUpdatedAccounts";
import RecentUpdatedInvoices from "./RecentUpdatedInvoices";
import RecentUpdatedPayments from "./RecentUpdatedPayments";

export default {
  components: {
    RecentUpdatedMeters,
    RecentUpdatedAccounts,
    RecentUpdatedInvoices,
    RecentUpdatedPayments
  },
};
</script>