<template>
  <div>
    <div class="px-3 py-2">
      <b-row class="align-items-center">
        <b-col sm="4" offset="8">
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text>
                <i class="icon-magnifier"></i>
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              type="text"
              class="form-control"
              placeholder="Search by meter serial#"
              autocomplete="off"
              v-model="serverParams.keyword"
              @input="onChange"
            />
          </b-input-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    serverParams: Object,
  },
  methods: {
    onChange() {
      this.serverParams.pageNumber = 1;
      this.$emit("onChange", this.serverParams);
    },
  },
};
</script>