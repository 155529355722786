<template>
  <CardTable :title="title" :columns="columns" :rows="rows" :isLoading="isLoading" :pagination="false">
    <template slot="action">
      <b-button :to="{ name: 'Payments' }" variant="primary" size="sm">New Payment</b-button>
    </template>
    <template #cell(currentReadingDate)="data">
      {{ dateFormat(data.item.currentReadingDate) }}
    </template>
    <template #cell(createdAt)="data">
      {{ dateTimeFormat(data.item.createdAt, "") }}
    </template>
    <template #cell(paymentDate)="data">
      {{ dateFormat(data.item.paymentDate, "") }}
    </template>
  </CardTable>
</template>

<script>
import { dateFormat, dateTimeFormat, numberFormat } from "@/shared/utils";
import { recentUpdatedPayments as columns } from "@/shared/columns";

export default {
  data: () => ({
    title: "Recent New Payments",
    columns,
    rows: [],
    isLoading: true,
    serverParams: {
      pageNumber: 1,
      pageSize: 10,
      orderBy: "createdAt desc",
    },
  }),
  created() {
    const self = this;
    self.getAll();
  },
  methods: {
    dateFormat,
    dateTimeFormat,
    numberFormat,
    getAll() {
      const self = this;

      self.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: "/transaction/payment",
          params: self.serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.rows = response.data.data;
          }
          self.isLoading = false;
        });
    },
  },
};
</script>