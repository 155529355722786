<template>
  <div class="animated fadeIn">
    <ForUser v-if="user.role.id == 0 || user.role.id == 2" />
    <ForAccount v-else-if="user.role.id == 1" />
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import ForUser from "./for-user"
  import ForAccount from "./for-account"

  export default {
    components: {
      ForUser,
      ForAccount
    },
    computed: {
      ...mapGetters("auth", ["user"]),
    },
  }
</script>
