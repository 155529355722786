<template>
  <div class="animated fadeIn">
    <CardTable
      :title="title"
      :columns="table.columns"
      :rows="table.rows"
      :isLoading="table.isLoading"
      :pageSize="serverParams.pageSize"
      :pageNumber="serverParams.pageNumber"
      :totalPage="table.totalPage"
      :totalRecords="table.totalRecords"
      @onPageChange="onPageChange"
    >
      <template #filter>
        <SectionFilter :serverParams="serverParams" @onChange="getAll" />
      </template>
      <template #cell(unitNumber)="data">
        {{ data.item.meterReading != null ? data.item.meterReading.unitNumber : data.item.meter.units.join(", ") }}
      </template>
      <template #cell(currentReadingKwh)="data">
        {{ data.item.meterReading != null ? numberFormat(data.item.meterReading.currentReadingKwh) : "-" }}
      </template>
      <template #cell(currentUsageKwh)="data">
        {{ data.item.meterReading != null ? numberFormat(data.item.meterReading.currentUsageKwh) : "-" }}
      </template>
      <template #cell(currentReadingDate)="data">
        {{ data.item.meterReading != null ? dateFormat(data.item.meterReading.currentReadingDate) : "-" }}
      </template>
      <template #cell(download)="data">
        <b-button size="sm" @click="handleDownload(data.item.invoice)">Download</b-button>
      </template>
    </CardTable>
  </div>
</template>

<script>
import { dateFormat, numberFormat } from "@/shared/utils";
import { meterReadingsPerAccount as columns } from "@/shared/columns";
import SectionFilter from "./SectionFilter.vue";

export default {
  components: {
    SectionFilter,
  },
  data: () => ({
    title: "Transactions",
    serverParams: {
      pageNumber: 1,
      pageSize: 100,
      keyword: null,
    },
    table: {
      isLoading: true,
      columns,
      rows: [],
      totalPage: 0,
      totalRecords: 0,
    },
  }),
  created() {
    const self = this;
    self.getAll();
  },
  methods: {
    numberFormat,
    dateFormat,
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ pageNumber: params });
      this.getAll();
    },
    getAll() {
      const self = this;

      self.table.isLoading = true;
      self.$store
        .dispatch("apis/get", {
          url: "/transaction/account",
          params: self.serverParams,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: response.message,
            });
          } else {
            self.table.rows = response.data.data;
            self.table.totalPage = response.data.totalPage;
            self.table.totalRecords = response.data.totalData;
          }
          self.table.isLoading = false;
        });
    },
    handleDownload(params) {
      const self = this;
      self.$store
        .dispatch("apis/download", {
          url: `/transaction/invoice/download/${params.id}`,
        })
        .then((response) => {
          if (response.error) {
            self.$message.error({
              zIndex: 10000,
              message: "File not found",
            });
          } else {
            let fileURL = window.URL.createObjectURL(new Blob([response])),
              fileLink = document.createElement("a");

            fileLink.href = fileURL;
            fileLink.setAttribute("download", params.fileName);
            document.body.appendChild(fileLink);

            fileLink.click();
          }
        });
    },
  },
};
</script>